import { FC } from "react";
import { useTranslation } from "react-i18next";
import { energyTypes } from "@repo/api-client";
import { UseQueryResult } from "@tanstack/react-query";
import { useUpdate } from "../state/useUpdate";
import { getSpotStats } from "@/features/graphs";

const UpdateAccuracy: FC<{
  data: UseQueryResult<energyTypes.SpotPrice, Error>;
}> = ({ data }) => {
  const { t } = useTranslation("layout-menu");
  if (data.isLoading) return null;
  if (!data.data || data.error) return null;

  const { avg } = getSpotStats(data.data);

  return (
    <>
      <div>
        <p className="text-left font-bold text-xs">
          {t("update_info.v1_accuracy")}
        </p>
        <p className="pt-1 text-left text-xs">
          {avg.quantified_accuracy ? avg.quantified_accuracy.toFixed(2) : "-"}%
        </p>
      </div>
      <div>
        <p className="text-left font-bold text-xs">
          {t("update_info.v2_accuracy")}
        </p>
        <p className="pt-1 text-left text-xs">
          {avg.volue_accuracy ? avg.volue_accuracy.toFixed(2) : "-"}%
        </p>
      </div>
    </>
  );
};

const UpdatePrediction: FC<{
  data: UseQueryResult<energyTypes.SpotPrice, Error>;
  currency: string;
}> = ({ data, currency }) => {
  const { t } = useTranslation("layout-menu");
  if (data.isLoading) return null;
  if (!data.data || data.error) return null;

  const { avg } = getSpotStats(data.data);

  const quantified = t("update_info.v1_prediction", {
    val: avg.quantified ? avg.quantified.toFixed(2) : "-",
    currency,
  });

  const volue = t("update_info.v2_prediction", {
    val: avg.volue ? avg.volue.toFixed(2) : "-",
    currency,
  });

  return (
    <>
      <div>
        <p className="text-left font-bold text-xs">
          {t("update_info.prediction")}
        </p>
        <p className="pt-1 text-left text-xs">{quantified}</p>
        <p className="pt-1 text-left text-xs">{volue}</p>
      </div>
    </>
  );
};

const UpdateTime: FC<{
  data: UseQueryResult<energyTypes.UpdateTimes, Error>;
}> = ({ data }) => {
  const { t } = useTranslation("layout-menu");
  const quantifiedData = data.data ? new Date(data.data.quantified) : undefined;
//   const volueData = data.data ? new Date(data.data.volue) : undefined;

  return (
    <>
      <div>
        <p className="text-left font-bold text-xs">
          {t("update_info.v1_prediction_time")}
        </p>
        <p className="pt-1 text-left text-xs">
          {quantifiedData ? quantifiedData.toLocaleString() : "-"}
        </p>
      </div>
      <div>
        <p className="text-left font-bold text-xs">
          {t("update_info.v2_prediction_time")}
        </p>
        <p className="pt-1 text-left text-xs">
          {quantifiedData ? quantifiedData.toLocaleString() : "-"}
        </p>
      </div>
    </>
  );
};

export const UpdateInfo: FC = () => {
  const { timeData, spotDataAcc, spotDataPred, currency } = useUpdate();

  return (
    <div className="md:mt-10 items-start my-5 flex flex-col gap-4 text-grey">
      <UpdateAccuracy data={spotDataAcc} />
      <UpdatePrediction data={spotDataPred} currency={currency} />
      <UpdateTime data={timeData} />
    </div>
  );
};
